<template>
  <app-layout>
    <div class="flex flex-col items-center pb-32">
      <div class="w-full px-6 pt-6 2xl:w-3/5 2xl:px-0">
        <div class="w-full mb-4 space-y-4">
          <div class="flex flex-col md:flex-row py-4">
            <div class="flex flex-row items-center">
              <sa-icon-button with-bg iconClass="bx bx-left-arrow-alt" @click="$router.push({ name: 'home.index' })" />
              <span class="mx-2 text-lg font-bold">
                Cerrar Dia
              </span>
            </div>
            <div class="flex flex-row items-center ml-auto">
              <el-button class="el-button--warning" @click="print">
                <span>Imprimir</span>
              </el-button>
            </div>
          </div>
          <div id="print-area" class="w-full mb-4 space-y-4 relative">
            <div class="items-center text-center py-2 justify-between">
              <div class="w-4/5 m-auto justify-center align-center">
                <div class="w-full flex flex-row items-center justify-center">
                  <img v-if="logo" :src="logo" alt="" style="width: 200px !important; height: 100px !important;">
                  <img v-else src="@/assets/img/logo.png" alt="" width="70px">
                </div>
                <div class="w-full flex flex-row items-center justify-center">
                  <span class="font-bold text-gray-600 text-3xl font-headline ml-1">
                    {{ $store.state.business.name }}
                  </span>
                </div>
              </div>
              <div class="w-full lg:w-1/3 m-auto justify-center align-center">
                <div class="w-full">
                  <div class="border bg-black border-black text-white outline-none">
                    Cerrar dia
                  </div>
                  <div class="border">
                    <div>
                      <strong>{{ todayDate }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!loading">
              <div v-if="isGeneral">
                <general-close-day></general-close-day>
              </div>
              <div v-else>
                <business-close-day
                  :businessInfoId="businessInfoId"
                ></business-close-day>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<style scoped>
@media print {
  body *:not(#print-area):not(#print-area *) {
    visibility: hidden;
  }

  #print-area {
    position: absolute;
    visibility: visible;
    top: 0;
    left: 0;
    -webkit-print-color-adjust: exact;
  }

  #print-area * {
    overflow-x: hidden;
  }
}
</style>
<script>
import BusinessGetters from '@/store/modules/business/getters';

export default {
  name: 'CloseDay',
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
    GeneralCloseDay: () => import('./GeneralCloseDay'),
    BusinessCloseDay: () => import('./BusinessCloseDay'),
  },
  data() {
    return {
      loading: true,
      businessInfoId: null,
    }
  },
  mounted() {
    this.businessInfoId = this.$route.params.businessInfoId;
    this.loading = false;
  },
  computed: {
    isGeneral() {
      return !this.businessInfoId;
    },
    logo() {
      return BusinessGetters.logo();
    },
    todayDate() {
      const format = this.$store.state.business.id
        ? `${this.$store.state.business.dateFormat?.toUpperCase()} hh:mm:ss A`
        : "MM/DD/YYYY hh:mm:ss A";
      return this.$moment(new Date()).format(format);
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
}
</script>
